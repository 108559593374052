import React, { useContext, useEffect, useState } from "react";
import axios from "./apis/api";
import { appContext } from "./AppContext";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const { storeToken } = useContext(appContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      let temp = true;
      if (!username) {
        setError("Please enter username.");
        temp = false;
      } else {
        setError("");
      }
      if (!password) {
        setError("Please enter password.");
        temp = false;
      } else {
        setError("");
      }
      if (temp) {
        await axios.post("login", { username, password }).then((res) => {
          if (res.data.error) {
            setError(res.data.error);
          } else {
            const result = storeToken(res.data.token);
            if (result) {
              setUsername("");
              setPassword("");
              navigate("/dashboard");
            }
          }
        });
      }
    } catch (err) {
      console.log("error : ", err);
      setError(err.message);
    }
  };

  return (
    <>
      <div className="authincation vh-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <a href="index.html">
                          <img src="images/igt-it-logo.png" className="img-fluid w-50" alt="" />
                        </a>
                      </div>
                      <h4 className="text-center mb-4">Sign in your account</h4>
                      <form action="/dashboad">
                        <div className="form-group">
                          <label className="form-label">Username</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => {
                              setError("");
                              setUsername(e.target.value);
                            }}
                          />
                        </div>
                        <label className="form-label">Password</label>
                        <div className="mb-3 position-relative">
                          <input
                            type="password"
                            id="dz-password"
                            className="form-control"
                            placeholder="******"
                            value={password}
                            onChange={(e) => {
                              setError("");
                              setPassword(e.target.value);
                            }}
                          />
                          <span className="show-pass eye">
                            <i className="fa fa-eye-slash" />
                            <i className="fa fa-eye" />
                          </span>
                        </div>
                        {error ? <div class="alert alert-danger solid alert-square ">{error}</div> : ""}
                        <div className="text-center">
                          <button type="button" className="btn btn-primary btn-block" onClick={handleLogin}>
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
