import React, { useState, useContext, useEffect } from "react";
import Header from "./Comman/Header";
import Sidebar from "./Comman/Sidebar";
import Footer from "./Comman/Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { appContext } from "./AppContext";
import axios from "./apis/api";
import { useParams, useLocation } from "react-router-dom";
import Copy from "./Comman/Copy";
import { Tooltip } from "react-tooltip";
import Pagination from "./Comman/Pagination";

export default function TokenDetails() {
  const location = useLocation();
  const { logout, getDecToken, formatAddress, formatDate, toggleMenu } = useContext(appContext);
  const [tokenDetails, setTokenDetails] = useState(null);
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [noteError, setNoteError] = useState("");
  const [noteSuccess, setNoteSuccess] = useState("");
  const [date, setDate] = useState("");
  const [actions, setActions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [editId, setEditId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  // social media
  const [logoUrl, setLogoUrl] = useState("");
  const [blog, setBlog] = useState("");
  const [coingecko, setCoingecko] = useState("");
  const [coinmarketcap, setCoinmarketcap] = useState("");
  const [discord, setDiscord] = useState("");
  const [github, setGithub] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [email, setEmail] = useState("");
  const [telegram, setTelegram] = useState("");
  const [website, setWebsite] = useState("");

  const [editTokenModal, setEditTokenModal] = useState(false);

  const openEditTokenModal = () => {
    setError("");
    setLogoUrl(tokenDetails?.logoUrl);
    setBlog(tokenDetails?.tokeninfo[0]?.blog);
    setCoingecko(tokenDetails?.tokeninfo[0]?.coingecko);
    setCoinmarketcap(tokenDetails?.tokeninfo[0]?.coinmarketcap);
    setDiscord(tokenDetails?.tokeninfo[0]?.discord);
    setGithub(tokenDetails?.tokeninfo[0]?.github);
    setLinkedin(tokenDetails?.tokeninfo[0]?.linkedin);
    setTwitter(tokenDetails?.tokeninfo[0]?.twitter);
    setEmail(tokenDetails?.tokeninfo[0]?.email);
    setTelegram(tokenDetails?.tokeninfo[0]?.telegram);
    setWebsite(tokenDetails?.tokeninfo[0]?.website);
    setEditTokenModal(true);
  };
  const closeEditTokenModal = () => {
    setError("");
    setLogoUrl("");
    setBlog("");
    setCoingecko("");
    setCoinmarketcap("");
    setDiscord("");
    setGithub("");
    setLinkedin("");
    setTwitter("");
    setEmail("");
    setTelegram("");
    setWebsite("");
    setEditTokenModal(false);
  };

  const { id } = location.state;

  const handleChange = (value) => {
    setText(value);
  };

  const getTokenDetails = async () => {
    try {
      const token = getDecToken();
      await axios
        .get(`gettokens/${id}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            setTokenDetails(res?.data[0]);
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getTokenDetails();
  }, [id]);

  const updateTokenDetails = async () => {
    try {
      if (tokenDetails && tokenDetails?.address) {
        const token = getDecToken();
        await axios
          .post(
            `updatetokendetails`,
            {
              address: tokenDetails?.address,
              logoUrl,
              blog,
              coingecko,
              coinmarketcap,
              discord,
              github,
              linkedin,
              twitter,
              email,
              telegram,
              website,
            },
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error && !res.data.status && res.data?.isLogin === false) {
              return logout();
            }
            if (res.data.error) {
              console.log("error : ", res.data.error);
              setError(res.data.error);
            } else {
              closeEditTokenModal();
            }
          });
      } else {
        setError("Token details not found.");
      }
    } catch (err) {
      console.log("error : ", err);
      setError(err.message);
    }
  };

  const insertAction = async () => {
    try {
      setNoteError("");
      setNoteSuccess("");
      const token = getDecToken();
      let newdate = new Date(date);
      await axios
        .post(
          `insertaction`,
          {
            address: tokenDetails?.address,
            chainid: tokenDetails?.chainId,
            date: newdate,
            note: text,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
            setNoteError(res.data.error);
            setNoteSuccess("");
          } else {
            setNoteError("");
            setText("");
            setNoteError("");
            setDate("");
            setNoteSuccess(res.data.message);
            closeEditTokenModal();
            getActions();
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setNoteError(err.message);
      setNoteSuccess("");
    }
  };

  const clear = () => {
    setText("");
    setDate("");
    setNoteSuccess("");
    setNoteError("");
    setEditId("");
  };

  const getActions = async () => {
    try {
      setLoading(true);
      const token = getDecToken();
      await axios
        .get(
          `getactionbyaddress?pageSize=${pageSize}&page=${currentPage}&address=${tokenDetails?.address}&search=${search}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            setLoading(false);
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
            setLoading(false);
          } else {
            setActions(res.data.items);
            setPages(res.data.pages ? res.data.pages : 1);
            setLoading(false);
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tokenDetails) {
      setActions(null);
      getActions();
    }
  }, [currentPage, pageSize, search, tokenDetails]);

  const deleteAction = async (id) => {
    try {
      const token = getDecToken();
      await axios
        .post(
          `deleteaction`,
          { id: id },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            getActions();
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const onEdit = (data) => {
    setEditId(data?._id);
    setText(data.note);
    setDate(formatDate(data.date, true));
  };

  const updateAction = async () => {
    try {
      setNoteError("");
      setNoteSuccess("");
      const token = getDecToken();
      let newdate = new Date(date);
      await axios
        .post(
          `updateaction`,
          {
            id: editId,
            date: newdate,
            note: text,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
            setNoteError(res.data.error);
            setNoteSuccess("");
          } else {
            setNoteError("");
            setText("");
            setNoteError("");
            setDate("");
            setNoteSuccess(res.data.message);
            closeEditTokenModal();
            setEditId("");
            getActions();
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setNoteError(err.message);
      setNoteSuccess("");
    }
  };

  return (
    <>
      <div id="main-wrapper" className={`show ${toggleMenu ? "menu-toggle" : ""}`}>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="row">
            <div className="col-xl-12">
              <div className="card m-md-5 m-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-8 pb-2">
                      <div className="profile">
                        <div className="staff">{tokenDetails ? <img src={tokenDetails?.logoUrl} alt="" /> : ""}</div>
                        <div className="staf-info">
                          <div className="d-flex align-items-center mb-2">
                            <h4 className="mb-0">Address :</h4>
                            <p className="ms-2 mb-0">
                              {formatAddress(tokenDetails?.address)} <Copy data={tokenDetails?.address} />
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <h4 className="mb-0">Name :</h4>
                            <p className="ms-2 mb-0">{tokenDetails?.name}</p>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <h4 className="mb-0">Symbol :</h4>
                            <p className="ms-2 mb-0">{tokenDetails?.symbol}</p>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <h4 className="mb-0">Note :</h4>
                            <p className="ms-2 mb-0">
                              {tokenDetails?.tokeninfo[0]?.note ? tokenDetails?.tokeninfo[0]?.note : "-"}
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <h4 className="mb-0">Social Media :</h4>
                            {tokenDetails?.tokeninfo?.length ? (
                              <div className="">
                                {tokenDetails?.tokeninfo[0]?.bscscan ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.bscscan}
                                    target="_blank"
                                    data-tooltip-id={`bscscan${101}`}
                                  >
                                    <Tooltip id={`bscscan${101}`} content={tokenDetails?.tokeninfo[0]?.bscscan} />
                                    <img src="images/svg/bsc-scan.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.coinmarketcap ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.coinmarketcap}
                                    target="_blank"
                                    data-tooltip-id={`coinmarketcap${102}`}
                                  >
                                    <Tooltip
                                      id={`coinmarketcap${102}`}
                                      content={tokenDetails?.tokeninfo[0]?.coinmarketcap}
                                    />
                                    <img src="images/svg/cmc.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.coingecko ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.coingecko}
                                    target="_blank"
                                    data-tooltip-id={`coingecko${103}`}
                                  >
                                    <Tooltip id={`coingecko${103}`} content={tokenDetails?.tokeninfo[0]?.coingecko} />
                                    <img src="images/svg/coin-gecko.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.discord ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.discord}
                                    target="_blank"
                                    data-tooltip-id={`discord${104}`}
                                  >
                                    <Tooltip id={`discord${104}`} content={tokenDetails?.tokeninfo[0]?.discord} />
                                    <img src="images/svg/discord.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.github ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.github}
                                    target="_blank"
                                    data-tooltip-id={`github${105}`}
                                  >
                                    <Tooltip id={`github${105}`} content={tokenDetails?.tokeninfo[0]?.github} />
                                    <img src="images/svg/github.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.linkedin ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.linkedin}
                                    target="_blank"
                                    data-tooltip-id={`linkedin${106}`}
                                  >
                                    <Tooltip id={`linkedin${106}`} content={tokenDetails?.tokeninfo[0]?.linkedin} />
                                    <img src="images/svg/linkdin.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.website ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.website}
                                    target="_blank"
                                    data-tooltip-id={`website${107}`}
                                  >
                                    <Tooltip id={`website${107}`} content={tokenDetails?.tokeninfo[0]?.website} />
                                    <img src="images/svg/website.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.email ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.email}
                                    target="_blank"
                                    data-tooltip-id={`email${108}`}
                                  >
                                    <Tooltip id={`email${108}`} content={tokenDetails?.tokeninfo[0]?.email} />
                                    <img src="images/svg/mail.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.twitter ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.twitter}
                                    target="_blank"
                                    data-tooltip-id={`twitter${109}`}
                                  >
                                    <Tooltip id={`twitter${109}`} content={tokenDetails?.tokeninfo[0]?.twitter} />
                                    <img src="images/svg/twt.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.telegram ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.telegram}
                                    target="_blank"
                                    data-tooltip-id={`telegram${110}`}
                                  >
                                    <Tooltip id={`telegram${110}`} content={tokenDetails?.tokeninfo[0]?.telegram} />
                                    <img src="images/svg/telegram.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.coinjournal ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.coinjournal}
                                    target="_blank"
                                    data-tooltip-id={`coinjournal${111}`}
                                  >
                                    <Tooltip
                                      id={`coinjournal${111}`}
                                      content={tokenDetails?.tokeninfo[0]?.coinjournal}
                                    />
                                    <img src="images/svg/coin-journal.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.facebook ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.facebook}
                                    target="_blank"
                                    data-tooltip-id={`facebook${112}`}
                                  >
                                    <Tooltip id={`facebook${112}`} content={tokenDetails?.tokeninfo[0]?.facebook} />
                                    <img src="images/svg/fb.svg" className="mx-2" width={10} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.gitbook ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.gitbook}
                                    target="_blank"
                                    data-tooltip-id={`gitbook${113}`}
                                  >
                                    <Tooltip id={`gitbook${113}`} content={tokenDetails?.tokeninfo[0]?.gitbook} />
                                    <img src="images/svg/git-book.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.instagram ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.instagram}
                                    target="_blank"
                                    data-tooltip-id={`instagram${114}`}
                                  >
                                    <Tooltip id={`instagram${114}`} content={tokenDetails?.tokeninfo[0]?.instagram} />
                                    <img src="images/svg/insta.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.map ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.map}
                                    target="_blank"
                                    data-tooltip-id={`map${115}`}
                                  >
                                    <Tooltip id={`map${115}`} content={tokenDetails?.tokeninfo[0]?.map} />
                                    <img src="images/svg/map.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.medium ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.medium}
                                    target="_blank"
                                    data-tooltip-id={`medium${116}`}
                                  >
                                    <Tooltip id={`medium${116}`} content={tokenDetails?.tokeninfo[0]?.medium} />
                                    <img src="images/svg/medium.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.pankswap ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.pankswap}
                                    target="_blank"
                                    data-tooltip-id={`pankswap${117}`}
                                  >
                                    <Tooltip id={`pankswap${117}`} content={tokenDetails?.tokeninfo[0]?.pankswap} />
                                    <img src="images/svg/pankswap.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.reddit ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.reddit}
                                    target="_blank"
                                    data-tooltip-id={`reddit${118}`}
                                  >
                                    <Tooltip id={`reddit${118}`} content={tokenDetails?.tokeninfo[0]?.reddit} />
                                    <img src="images/svg/reddit.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.trustwallet ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.trustwallet}
                                    target="_blank"
                                    data-tooltip-id={`trustwallet${119}`}
                                  >
                                    <Tooltip
                                      id={`trustwallet${119}`}
                                      content={tokenDetails?.tokeninfo[0]?.trustwallet}
                                    />
                                    <img src="images/svg/trust-wallet.svg" className="mx-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                                {tokenDetails?.tokeninfo[0]?.youtube ? (
                                  <a
                                    href={tokenDetails?.tokeninfo[0]?.youtube}
                                    target="_blank"
                                    data-tooltip-id={`youtube${120}`}
                                  >
                                    <Tooltip id={`youtube${120}`} content={tokenDetails?.tokeninfo[0]?.youtube} />
                                    <img src="images/svg/youtube.svg" className="me-2" width={15} alt="" />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              "Token info not found."
                            )}
                          </div>

                          <button
                            type="button"
                            className="btn btn-sm  btn-info px-3 py-2"
                            onClick={() => openEditTokenModal()}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-4">
                      <form action="/action_page.php">
                        <div className="d-flex justify-content-between border-bottom mb-3 pb-2 mt-xl-0 mt-3">
                          <span className="font-w600 text-black mt-4">Start Date</span>
                          <span>
                            <input name="datepicker" type="date" className="datepicker-default form-control" />
                          </span>
                        </div>
                        <div className="d-flex justify-content-between border-bottom mb-3 pb-2">
                          <span className="font-w600 text-black mt-4">End Date</span>
                          <span>
                            <input name="datepicker" type="date" className="datepicker-default form-control" />
                          </span>
                        </div>
                      </form>
                      <div className="mt-4">
                        <button id="follow-button" className="btn btn-primary m-2">
                          Follow
                        </button>
                        <a href="javascript:void(0);" className="btn btn-secondary m-2">
                          Message
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="m-md-5 m-0">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-12" style={{ height: "260px" }}>
                    {" "}
                    <ReactQuill
                      style={{ height: "200px" }}
                      theme="snow"
                      value={text}
                      onChange={handleChange}
                      // placeholder=""
                    />
                    <br />
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="d-flex justify-content-between border-bottom mb-3 pb-2 mt-md-0 mt-3">
                      <span className="font-w600 text-black mt-4">Date</span>
                      <span>
                        <input
                          name="datepicker"
                          type="date"
                          className="datepicker-default form-control d-inline"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </span>
                    </div>
                    {noteError ? (
                      <div class="alert alert-danger p-2 px-3" role="alert">
                        {noteError}
                      </div>
                    ) : noteSuccess ? (
                      <div class="alert alert-success p-2 px-3" role="alert">
                        {noteSuccess}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-dark m-2" onClick={clear}>
                        Clear
                      </button>
                      {editId ? (
                        <button id="follow-button" className="btn btn-primary m-2" onClick={updateAction}>
                          Update
                        </button>
                      ) : (
                        <button id="follow-button" className="btn btn-primary m-2" onClick={insertAction}>
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div>
            <div className="container-fluid">
              <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
                <div className="input-group search-area d-inline-flex me-2">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Search here"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button type="button" className="input-group-text" onClick={getActions}>
                      <i className="flaticon-381-search-2" />
                    </button>
                  </div>
                  <div className="input-group-append">
                    <button type="button" className="input-group-text" onClick={() => setSearch("")}>
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Note</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {actions && actions?.length
                              ? actions?.map((item, index) => {
                                  let ind = currentPage * pageSize - pageSize + index + 1;
                                  return (
                                    <tr>
                                      <td className="patient-info ps-0">
                                        <span>{ind}</span>
                                      </td>
                                      <td>
                                        {item.note ? (
                                          <div
                                            dangerouslySetInnerHTML={{ __html: item.note }}
                                            style={{
                                              width: "200px",
                                              maxHeight: "70px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                            }}
                                          />
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>{item.date ? formatDate(item.date) : "-"}</td>
                                      <td>
                                        <span className="me-3">
                                          <a
                                            type="button"
                                            className="edit-appointment mx-2"
                                            onClick={() => onEdit(item)}
                                          >
                                            <i className="fa fa-pencil fs-18 " aria-hidden="true" />
                                          </a>
                                          <a
                                            type="button"
                                            className="edit-appointment mx-2"
                                            onClick={() => deleteAction(item._id)}
                                          >
                                            <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                          </a>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                        {loading ? (
                          <div className="text-center">
                            <p>Loading...</p>
                          </div>
                        ) : !actions || !actions.length ? (
                          <div className="text-center">
                            <p>No data found.</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pages={pages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${editTokenModal ? "modal fade show" : "modal fade"}`}
              id="exampleModal"
              style={editTokenModal ? { display: "block" } : { display: "none" }}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Token
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={closeEditTokenModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Logo Url {tokenDetails?.logoUrl ? <span className="text-danger">*</span> : ""} :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="Blog"
                              value={logoUrl}
                              onChange={(e) => setLogoUrl(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Blog {tokenDetails?.tokeninfo[0]?.blog ? <span className="text-danger">*</span> : ""} :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="Blog"
                              value={blog}
                              onChange={(e) => setBlog(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              CoinGecko{" "}
                              {tokenDetails?.tokeninfo[0]?.coingecko ? <span className="text-danger">*</span> : ""} :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="CoinGecko"
                              value={coingecko}
                              onChange={(e) => setCoingecko(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              CoinMarketCap{" "}
                              {tokenDetails?.tokeninfo[0]?.coinmarketcap ? <span className="text-danger">*</span> : ""}{" "}
                              :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="CoinMarketCap"
                              value={coinmarketcap}
                              onChange={(e) => setCoinmarketcap(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Discord{" "}
                              {tokenDetails?.tokeninfo[0]?.discord ? <span className="text-danger">*</span> : ""} :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="Discord"
                              value={discord}
                              onChange={(e) => setDiscord(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Github {tokenDetails?.tokeninfo[0]?.github ? <span className="text-danger">*</span> : ""}{" "}
                              :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="Github"
                              value={github}
                              onChange={(e) => setGithub(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              LinkedIn{" "}
                              {tokenDetails?.tokeninfo[0]?.linkedin ? <span className="text-danger">*</span> : ""} :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="LinkedIn"
                              value={linkedin}
                              onChange={(e) => setLinkedin(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Twitter{" "}
                              {tokenDetails?.tokeninfo[0]?.twitter ? <span className="text-danger">*</span> : ""} :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="Twitter"
                              value={twitter}
                              onChange={(e) => setTwitter(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Email {tokenDetails?.tokeninfo[0]?.email ? <span className="text-danger">*</span> : ""} :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Telegram{" "}
                              {tokenDetails?.tokeninfo[0]?.telegram ? <span className="text-danger">*</span> : ""} :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="Telegram"
                              value={telegram}
                              onChange={(e) => setTelegram(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Website{" "}
                              {tokenDetails?.tokeninfo[0]?.website ? <span className="text-danger">*</span> : ""} :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="Website"
                              value={website}
                              onChange={(e) => setWebsite(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    {error ? (
                      <div class="alert alert-danger p-2 px-3" role="alert">
                        {error}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={closeEditTokenModal}>
                      Close
                    </button>
                    <button type="button" className="btn btn-primary" onClick={updateTokenDetails}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
