// import React from 'react'
import Header from "./Comman/Header";
import Sidebar from "./Comman/Sidebar";
import Footer from "./Comman/Footer";
import React, { useState, useContext, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { appContext } from "./AppContext";
import axios from "./apis/api";
import Pagination from "./Comman/Pagination";
import Copy from "./Comman/Copy";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Actions() {
  const navigate = useNavigate();
  const { logout, getDecToken, formatAddress, formatDate, toggleMenu } = useContext(appContext);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [actions, setActions] = useState(null);
  const [selectedChains, setSelectedChains] = useState({ id: "1", name: "Ethereum" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getActions = async () => {
    try {
      setLoading(true);
      const token = getDecToken();
      let newdate = "";
      if (search) {
        newdate = new Date(search);
      }
      await axios
        .get(`getAllActions?pageSize=${pageSize}&page=${currentPage}&search=${newdate}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            setLoading(false);
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
            setLoading(false);
          } else {
            setActions(res.data.items);
            setPages(res.data.pages ? res.data.pages : 1);
            setLoading(false);
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    setActions(null);
    getActions();
  }, [currentPage, pageSize, search, selectedChains]);

  const deleteAction = async (id) => {
    try {
      const token = getDecToken();
      await axios
        .post(
          `deleteaction`,
          { id: id },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            getActions();
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  return (
    <>
      <div id="main-wrapper" className={`show ${toggleMenu ? "menu-toggle" : ""}`}>
        <Header />
        <Sidebar />

        <div className="content-body">
          {/* row */}
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a href="button">Actions</a>
                </li>
              </ol>
            </div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
              <div className="input-group search-area d-inline-flex me-2">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Search here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button type="button" className="input-group-text" onClick={getActions}>
                    <i className="flaticon-381-search-2" />
                  </button>
                </div>
                <div className="input-group-append">
                  <button type="button" className="input-group-text" onClick={() => setSearch("")}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Address</th>
                            <th>Note</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {actions && actions?.length
                            ? actions?.map((item, index) => {
                                let ind = currentPage * pageSize - pageSize + index + 1;
                                return (
                                  <tr>
                                    <td className="patient-info ps-0">
                                      <span>{ind}</span>
                                    </td>
                                    <td>
                                      {item.address ? (
                                        <>
                                          {formatAddress(item.address)}&nbsp;
                                          <Copy data={item.address} />
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {item.note ? (
                                        <div
                                          dangerouslySetInnerHTML={{ __html: item.note }}
                                          style={{
                                            width: "200px",
                                            maxHeight: "70px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>{item.date ? formatDate(item.date) : "-"}</td>
                                    <td>
                                      <span className="me-3">
                                        {/* <a type="button" className="edit-appointment mx-2" onClick={() => onEdit(item)}>
                                          <i className="fa fa-pencil fs-18 " aria-hidden="true" />
                                        </a> */}
                                        <a
                                          type="button"
                                          className="edit-appointment mx-2"
                                          onClick={() => deleteAction(item._id)}
                                        >
                                          <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                        </a>
                                        <button
                                          type="button"
                                          className="btn btn-sm  btn-primary px-3 py-2 mx-2"
                                          onClick={() => navigate(`/tokendetails`, { state: { id: item.address } })}
                                        >
                                          View {"->"}
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !actions || !actions.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
