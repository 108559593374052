import React, { useState } from "react";

const Pagination = ({ currentPage = 1, setCurrentPage, pageSize = 10, setPageSize, pages }) => {
  const [pageNo, setPageNo] = useState("");
  const goTo = () => {
    if (pageNo) {
      setCurrentPage(pageNo);
      setPageNo("");
    } else {
      setCurrentPage(1);
    }
  };
  return (
    <>
      <div className="row">
        <div
          className="dataTables_length col-md-4 col-12 px-3 d-flex justify-content-center"
          id="DataTables_Table_0_length"
        >
          <label>
            <select
              name="DataTables_Table_0_length"
              aria-controls="DataTables_Table_0"
              className="custom-select custom-select-sm form-control form-control-sm"
              onChange={(e) => setPageSize(Number(e.target.value))}
              value={pageSize}
            >
              <option value={10} select>
                10
              </option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </label>
        </div>
        <div
          className="dataTables_paginate paging_numbers col-md-4 col-12 mt-md-0 mt-3 px-3 text-center d-flex justify-content-center"
          id="DataTables_Table_0_paginate"
        >
          <ul className="pagination text-center">
            <li className={`paginate_button page-item ${currentPage === 1 ? "active" : ""}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1 ? true : false}
              >
                {"<<"}
              </button>
            </li>
            {currentPage - 2 > 0 ? (
              <li className="paginate_button page-item">
                <button className="page-link" onClick={() => setCurrentPage((prev) => prev - 2)}>
                  {currentPage - 2}
                </button>
              </li>
            ) : (
              ""
            )}
            {currentPage - 1 > 0 ? (
              <li className="paginate_button page-item">
                <button className="page-link" onClick={() => setCurrentPage((prev) => prev - 1)}>
                  {currentPage - 1}
                </button>
              </li>
            ) : (
              ""
            )}
            <li className="paginate_button page-item active">
              <button className="page-link" disabled={true}>
                {currentPage}
              </button>
            </li>
            {currentPage + 1 <= pages ? (
              <li className="paginate_button page-item">
                <button className="page-link" onClick={() => setCurrentPage((prev) => prev + 1)}>
                  {currentPage + 1}
                </button>
              </li>
            ) : (
              ""
            )}
            {currentPage + 2 <= pages ? (
              <li className="paginate_button page-item">
                <button className="page-link" onClick={() => setCurrentPage((prev) => prev + 2)}>
                  {currentPage + 2}
                </button>
              </li>
            ) : (
              ""
            )}
            <li className={`paginate_button page-item ${currentPage === pages ? "active" : ""}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage(pages)}
                disabled={currentPage === pages ? true : false}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </div>
        <div
          className="dataTables_paginate paging_numbers col-md-4 col-12 mt-md-0 mt-3 px-3 text-center d-flex justify-content-center"
          id="DataTables_Table_0_paginate"
        >
          <ul className="pagination text-center">
            <li className={`paginate_button page-item ${currentPage === 1 ? "active" : ""}`}>
              <input
                type="number"
                className="form-control mx-2 px-3 py-2"
                id="name1"
                placeholder="go to"
                style={{ width: "100px", height: "auto", marginTop: "3px" }}
                value={pageNo}
                onChange={(e) => setPageNo(e.target.value)}
              />
            </li>
            <li className={`paginate_button page-item ${currentPage === pages ? "active" : ""}`}>
              <button className="btn btn-primary px-3 py-2" onClick={goTo}>
                Go To
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Pagination;
