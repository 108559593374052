import React, { createContext, useState } from "react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

export const appContext = createContext();

const AppContext = ({ children }) => {
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);
  const storeToken = (token) => {
    try {
      let enctoken = CryptoJS.AES.encrypt(token, process.env.REACT_APP_KEY).toString();
      localStorage.setItem("4vs1ad4f3thdfa", enctoken);
      return true;
    } catch (err) {
      console.log("error : ", err);
      return false;
    }
  };

  const getDecToken = () => {
    try {
      const enctoken = localStorage.getItem("4vs1ad4f3thdfa");
      let dectoken = CryptoJS.AES.decrypt(enctoken, process.env.REACT_APP_KEY).toString(CryptoJS.enc.Utf8);
      return dectoken;
    } catch (err) {
      console.log("error : ", err);
      return false;
    }
  };
  const logout = () => {
    try {
      localStorage.removeItem("4vs1ad4f3thdfa");
      navigate("/");
    } catch (err) {
      console.log("error : ", err);
      return false;
    }
  };

  const formatAddress = (text) => {
    if (text) {
      return text.substr(0, 7) + "..." + text.substr(-7, 7);
    } else {
      return "";
    }
  };

  const formatDate = (date, swap) => {
    try {
      if (date) {
        if (swap) {
          let newDate = new Date(date);
          let day = newDate.getDate();
          let month = newDate.getMonth() + 1;
          let year = newDate.getFullYear();
          return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
        } else {
          let newDate = new Date(date);
          let day = newDate.getDate();
          let month = newDate.getMonth() + 1;
          let year = newDate.getFullYear();
          return `${day}-${month}-${year}`;
        }
      } else {
        return "";
      }
    } catch (err) {
      console.log("error : ", err);
      return "";
    }
  };

  return (
    <appContext.Provider
      value={{ storeToken, getDecToken, logout, formatAddress, formatDate, toggleMenu, setToggleMenu }}
    >
      {children}
    </appContext.Provider>
  );
};

export default AppContext;
