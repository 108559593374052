// import React from 'react'
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import React, { useState, useContext, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { appContext } from "../AppContext";
import axios from "../apis/api";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";
import { Tooltip } from "react-tooltip";
import { Link, useNavigate } from "react-router-dom";

export default function DeadTokens() {
  const navigate = useNavigate();
  const { logout, getDecToken, formatAddress, toggleMenu } = useContext(appContext);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [textErr, setTextErr] = useState("");
  const [search, setSearch] = useState("");
  const [tokens, setTokens] = useState(null);
  const [chains, setChains] = useState(null);
  const [selectedChains, setSelectedChains] = useState({ id: "1", name: "Ethereum" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const [noteModal, setNoteModal] = useState(false);
  const [address, setAddress] = useState(false);

  const openNoteModal = async (address, note = "") => {
    setAddress(address);
    setNoteModal(true);
    setText(note);
  };
  const closeNoteModal = () => {
    setNoteModal(false);
    setText("");
    setAddress("");
  };

  const handleChange = (value) => {
    setText(value);
  };

  const getTokens = async () => {
    try {
      setLoading(true);
      const token = getDecToken();
      if (selectedChains && selectedChains?.id) {
        await axios
          .get(
            `getdeadtokens?pageSize=${pageSize}&page=${currentPage}&search=${search}&chainid=${selectedChains?.id}`,
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error && !res.data.status && res.data?.isLogin === false) {
              setLoading(false);
              return logout();
            }
            if (res.data.error) {
              console.log("error : ", res.data.error);
              setLoading(false);
            } else {
              setTokens(res.data.items);
              setPages(res.data.pages ? res.data.pages : 1);
              setLoading(false);
            }
          });
      } else {
        await axios
          .get(`getalltokens?pageSize=${pageSize}&page=${currentPage}&search=${search}`, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.error && !res.data.status && res.data?.isLogin === false) {
              setLoading(false);
              return logout();
            }
            if (res.data.error) {
              console.log("error : ", res.data.error);
              setLoading(false);
            } else {
              setTokens(res.data.items);
              setPages(res.data.pages ? res.data.pages : 1);
              setLoading(false);
            }
          });
      }
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };

  const getAllChains = async () => {
    try {
      const token = getDecToken();
      await axios
        .get(`getallchains2`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            setChains(res.data.items);
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    setTokens(null);
    getTokens();
  }, [currentPage, pageSize, search, selectedChains]);

  useEffect(() => {
    getAllChains();
  }, []);

  const addNote = async () => {
    try {
      setTextErr("");
      if (address) {
        const token = getDecToken();
        await axios
          .post(
            `updatenote`,
            { address, note: text },
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error && !res.data.status && res.data?.isLogin === false) {
              return logout();
            }
            if (res.data.error) {
              setTextErr(res.data.error);
            } else {
              setTextErr("");
              closeNoteModal();
              setAddress("");
              setText("");
              getTokens();
            }
          });
      } else {
        setTextErr("Address not found.");
      }
    } catch (err) {
      console.log("error : ", err);
      setTextErr(err.message);
    }
  };

  const changeStatus = async (address, status) => {
    try {
      const token = getDecToken();
      await axios
        .post(
          `changestatus`,
          { address: address, status: status },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            getTokens();
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  return (
    <>
      <div id="main-wrapper" className={`show ${toggleMenu ? "menu-toggle" : ""}`}>
        <Header />
        <Sidebar />

        <div className="content-body">
          {/* row */}
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a href="#">Dead Tokens</a>
                </li>
              </ol>
            </div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
              <div className="input-group search-area d-inline-flex me-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button type="button" className="input-group-text" onClick={getTokens}>
                    <i className="flaticon-381-search-2" />
                  </button>
                </div>
              </div>
              <div className="ms-auto">
                {/* <a href="#" className="btn btn-primary btn-rounded add-appointment" data-bs-toggle="modal" data-bs-target="#exampleModal">+ Book Appointment</a> */}
                {/* <a href="#" className="btn btn-primary btn-rounded">Doctor Schedule</a> */}
                <div class="dropdown custom-dropdown">
                  <button type="button" class="btn btn-sm btn-primary px-4" data-bs-toggle="dropdown">
                    {selectedChains && selectedChains?.name ? selectedChains?.name : "-Select-"}
                    <i class="fa fa-angle-down ms-3"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    {chains && chains?.length
                      ? chains.map((item, index) => {
                          return (
                            <a
                              class="dropdown-item"
                              type="button"
                              onClick={() => setSelectedChains({ id: item.chainId, name: item.name })}
                            >
                              {item.name}
                            </a>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Symbol</th>
                            <th>Social Media</th>
                            <th>Note</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tokens && tokens?.length
                            ? tokens?.map((item, index) => {
                                let ind = currentPage * pageSize - pageSize + index + 1;
                                return (
                                  <tr>
                                    <td className="patient-info ps-0">
                                      <span>{ind}</span>
                                    </td>
                                    <td className="patient-info ps-0">
                                      <span>
                                        <img
                                          src={item.logoUrl ? item.logoUrl : "/images/xeer-192.png"}
                                          alt=""
                                          style={{ width: "35px", height: "35px" }}
                                        />
                                      </span>
                                      <span className="text-nowrap ms-2">{item?.name ? item?.name : "-"}</span>
                                    </td>
                                    <td>
                                      {item.address ? (
                                        <>
                                          {formatAddress(item.address)}&nbsp;
                                          <Copy data={item.address} />
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>{item.symbol ? item.symbol : "-"}</td>
                                    <td className="">
                                      {item?.tokeninfo?.length ? (
                                        <div className="">
                                          {item?.tokeninfo?.bscscan ? (
                                            <a
                                              href={item?.tokeninfo?.bscscan}
                                              target="_blank"
                                              data-tooltip-id={`bscscan${ind}`}
                                            >
                                              <Tooltip id={`bscscan${ind}`} content={item?.tokeninfo?.bscscan} />
                                              <img src="images/svg/bsc-scan.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.coinmarketcap ? (
                                            <a
                                              href={item?.tokeninfo?.coinmarketcap}
                                              target="_blank"
                                              data-tooltip-id={`coinmarketcap${ind}`}
                                            >
                                              <Tooltip
                                                id={`coinmarketcap${ind}`}
                                                content={item?.tokeninfo?.coinmarketcap}
                                              />
                                              <img src="images/svg/cmc.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.coingecko ? (
                                            <a
                                              href={item?.tokeninfo?.coingecko}
                                              target="_blank"
                                              data-tooltip-id={`coingecko${ind}`}
                                            >
                                              <Tooltip id={`coingecko${ind}`} content={item?.tokeninfo?.coingecko} />
                                              <img src="images/svg/coin-gecko.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.discord ? (
                                            <a
                                              href={item?.tokeninfo?.discord}
                                              target="_blank"
                                              data-tooltip-id={`discord${ind}`}
                                            >
                                              <Tooltip id={`discord${ind}`} content={item?.tokeninfo?.discord} />
                                              <img src="images/svg/discord.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.github ? (
                                            <a
                                              href={item?.tokeninfo?.github}
                                              target="_blank"
                                              data-tooltip-id={`github${ind}`}
                                            >
                                              <Tooltip id={`github${ind}`} content={item?.tokeninfo?.github} />
                                              <img src="images/svg/github.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.linkedin ? (
                                            <a
                                              href={item?.tokeninfo?.linkedin}
                                              target="_blank"
                                              data-tooltip-id={`linkedin${ind}`}
                                            >
                                              <Tooltip id={`linkedin${ind}`} content={item?.tokeninfo?.linkedin} />
                                              <img src="images/svg/linkdin.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.website ? (
                                            <a
                                              href={item?.tokeninfo?.website}
                                              target="_blank"
                                              data-tooltip-id={`website${ind}`}
                                            >
                                              <Tooltip id={`website${ind}`} content={item?.tokeninfo?.website} />
                                              <img src="images/svg/website.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.email ? (
                                            <a
                                              href={item?.tokeninfo?.email}
                                              target="_blank"
                                              data-tooltip-id={`email${ind}`}
                                            >
                                              <Tooltip id={`email${ind}`} content={item?.tokeninfo?.email} />
                                              <img src="images/svg/mail.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.twitter ? (
                                            <a
                                              href={item?.tokeninfo?.twitter}
                                              target="_blank"
                                              data-tooltip-id={`twitter${ind}`}
                                            >
                                              <Tooltip id={`twitter${ind}`} content={item?.tokeninfo?.twitter} />
                                              <img src="images/svg/twt.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.telegram ? (
                                            <a
                                              href={item?.tokeninfo?.telegram}
                                              target="_blank"
                                              data-tooltip-id={`telegram${ind}`}
                                            >
                                              <Tooltip id={`telegram${ind}`} content={item?.tokeninfo?.telegram} />
                                              <img src="images/svg/telegram.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.coinjournal ? (
                                            <a
                                              href={item?.tokeninfo?.coinjournal}
                                              target="_blank"
                                              data-tooltip-id={`coinjournal${ind}`}
                                            >
                                              <Tooltip
                                                id={`coinjournal${ind}`}
                                                content={item?.tokeninfo?.coinjournal}
                                              />
                                              <img
                                                src="images/svg/coin-journal.svg"
                                                className="mx-2"
                                                width={15}
                                                alt=""
                                              />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.facebook ? (
                                            <a
                                              href={item?.tokeninfo?.facebook}
                                              target="_blank"
                                              data-tooltip-id={`facebook${ind}`}
                                            >
                                              <Tooltip id={`facebook${ind}`} content={item?.tokeninfo?.facebook} />
                                              <img src="images/svg/fb.svg" className="mx-2" width={10} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.gitbook ? (
                                            <a
                                              href={item?.tokeninfo?.gitbook}
                                              target="_blank"
                                              data-tooltip-id={`gitbook${ind}`}
                                            >
                                              <Tooltip id={`gitbook${ind}`} content={item?.tokeninfo?.gitbook} />
                                              <img src="images/svg/git-book.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.instagram ? (
                                            <a
                                              href={item?.tokeninfo?.instagram}
                                              target="_blank"
                                              data-tooltip-id={`instagram${ind}`}
                                            >
                                              <Tooltip id={`instagram${ind}`} content={item?.tokeninfo?.instagram} />
                                              <img src="images/svg/insta.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.map ? (
                                            <a
                                              href={item?.tokeninfo?.map}
                                              target="_blank"
                                              data-tooltip-id={`map${ind}`}
                                            >
                                              <Tooltip id={`map${ind}`} content={item?.tokeninfo?.map} />
                                              <img src="images/svg/map.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.medium ? (
                                            <a
                                              href={item?.tokeninfo?.medium}
                                              target="_blank"
                                              data-tooltip-id={`medium${ind}`}
                                            >
                                              <Tooltip id={`medium${ind}`} content={item?.tokeninfo?.medium} />
                                              <img src="images/svg/medium.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.pankswap ? (
                                            <a
                                              href={item?.tokeninfo?.pankswap}
                                              target="_blank"
                                              data-tooltip-id={`pankswap${ind}`}
                                            >
                                              <Tooltip id={`pankswap${ind}`} content={item?.tokeninfo?.pankswap} />
                                              <img src="images/svg/pankswap.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.reddit ? (
                                            <a
                                              href={item?.tokeninfo?.reddit}
                                              target="_blank"
                                              data-tooltip-id={`reddit${ind}`}
                                            >
                                              <Tooltip id={`reddit${ind}`} content={item?.tokeninfo?.reddit} />
                                              <img src="images/svg/reddit.svg" className="mx-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.trustwallet ? (
                                            <a
                                              href={item?.tokeninfo?.trustwallet}
                                              target="_blank"
                                              data-tooltip-id={`trustwallet${ind}`}
                                            >
                                              <Tooltip
                                                id={`trustwallet${ind}`}
                                                content={item?.tokeninfo?.trustwallet}
                                              />
                                              <img
                                                src="images/svg/trust-wallet.svg"
                                                className="mx-2"
                                                width={15}
                                                alt=""
                                              />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {item?.tokeninfo?.youtube ? (
                                            <a
                                              href={item?.tokeninfo?.youtube}
                                              target="_blank"
                                              data-tooltip-id={`youtube${ind}`}
                                            >
                                              <Tooltip id={`youtube${ind}`} content={item?.tokeninfo?.youtube} />
                                              <img src="images/svg/youtube.svg" className="me-2" width={15} alt="" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : (
                                        "Token info not found."
                                      )}
                                    </td>
                                    <td>
                                      <div>
                                        {item?.tokeninfo?.note ? (
                                          <>
                                            {item?.tokeninfo?.note}
                                            <button
                                              type="button"
                                              className="btn btn-sm  btn-info px-2 py-1 mx-2"
                                              onClick={() => openNoteModal(item.address, item?.tokeninfo?.note)}
                                            >
                                              <small>Edit</small>
                                            </button>
                                          </>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btn-sm  btn-primary px-3 py-2"
                                            onClick={() => openNoteModal(item.address, item?.tokeninfo?.note)}
                                          >
                                            Add Note
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-sm  btn-primary px-3 py-2 mx-2"
                                        onClick={() => navigate(`/tokendetails`, { state: { id: item.address } })}
                                      >
                                        View {"->"}
                                      </button>
                                      {item?.status ? (
                                        <button
                                          type="button"
                                          className="btn btn-sm  btn-danger px-3 py-2 mx-2"
                                          onClick={() => changeStatus(item.address, false)}
                                        >
                                          Disable
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-sm  btn-success px-3 py-2 mx-2"
                                          onClick={() => changeStatus(item.address, true)}
                                        >
                                          Enable
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !tokens || !tokens.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${noteModal ? "modal fade show" : "modal fade"}`}
            id="basicModal"
            style={noteModal ? { display: "block" } : { display: "none" }}
          >
            <div className="modal-dialog  modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add Token Note</h5>
                  <button type="button" className="btn-close" onClick={closeNoteModal}></button>
                </div>
                <div className="modal-body ">
                  <div className="form-group">
                    <label className="col-form-label">Address :</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </div>
                  {textErr ? <small className="text-danger">{textErr}</small> : ""}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger light" onClick={closeNoteModal}>
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={addNote}>
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
