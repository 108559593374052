import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import React, { useState, useContext, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { appContext } from "../AppContext";
import axios from "../apis/api";
import Copy from "../Comman/Copy";
import Pagination from "../Comman/Pagination";
import { Link } from "react-router-dom";

export default function Chains() {
  const { logout, getDecToken, formatAddress, toggleMenu } = useContext(appContext);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [search, setSearch] = useState("");
  const [chains, setChains] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const [editChainModal, setEditChainModal] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [explorer, setExplorer] = useState("");
  const [rpcEndpoint, setRpcEndpoint] = useState("");
  const [geckoId, setGeckoId] = useState("");
  const [editId, setEditId] = useState("");

  const [error, setError] = useState("");

  const [logoUrlErr, setLogoUrlErr] = useState("");
  const [explorerErr, setExplorerErr] = useState("");
  const [rpcEndpointErr, setRpcEndpointErr] = useState("");
  const [geckoIdErr, setGeckoIdErr] = useState("");

  const closeEditChainModal = () => {
    setEditChainModal(false);
    setEditId("");
    setLogoUrl("");
    setExplorer("");
    setRpcEndpoint("");
    setGeckoId("");
    setLogoUrlErr("");
    setExplorerErr("");
    setRpcEndpointErr("");
    setGeckoIdErr("");
    setError("");
  };

  const editChian = (data) => {
    setEditId(data.chainId);
    setLogoUrl(data.icon);
    setExplorer(data.explorer);
    setRpcEndpoint(data.rpcEndpoint);
    setGeckoId(data.gecko_id);
    setEditChainModal(true);
  };

  const getChains = async () => {
    try {
      setLoading(true);
      const token = getDecToken();
      await axios
        .get(`getallchains?pageSize=${pageSize}&page=${currentPage}&search=${search}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            setLoading(false);
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
            setLoading(false);
          } else {
            setChains(res.data.items);
            setPages(res.data.pages ? res.data.pages : 1);
            setLoading(false);
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };

  const updateChain = async () => {
    try {
      setError("");
      setLogoUrlErr("");
      setExplorerErr("");
      setGeckoIdErr("");
      setRpcEndpointErr("");
      let temp = true;
      if (!editId) {
        setError("Edit chain id not found.");
        temp = false;
      } else {
        setError("");
      }
      if (!logoUrl) {
        setLogoUrlErr("Logo url is require.");
        temp = false;
      } else {
        setLogoUrlErr("");
      }
      if (!explorer) {
        setExplorerErr("Explorer url is require.");
        temp = false;
      } else {
        setExplorerErr("");
      }
      if (!rpcEndpoint) {
        setRpcEndpointErr("Rpc endpoint is require.");
        temp = false;
      } else {
        setRpcEndpointErr("");
      }
      if (!geckoId) {
        setGeckoIdErr("Gecko id is require.");
        temp = false;
      } else {
        setGeckoIdErr("");
      }
      if (temp) {
        const token = getDecToken();
        await axios
          .post(
            `updatechaindetails`,
            { chainid: editId, explorer: explorer, gecko_id: geckoId, icon: logoUrl, rpcEndpoint: rpcEndpoint },
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error && !res.data.status && res.data?.isLogin === false) {
              return logout();
            }
            if (res.data.error) {
              setError(res.data.error);
            } else {
              closeEditChainModal("");
              getChains();
            }
          });
      }
    } catch (err) {
      console.log("error : ", err);
      setError(err.message);
    }
  };

  useEffect(() => {
    setChains(null);
    getChains();
  }, [currentPage, pageSize, search]);
  return (
    <div>
      <div id="main-wrapper" className={`show ${toggleMenu ? "menu-toggle" : ""}`}>
        <Header />
        <Sidebar />

        <div className="content-body">
          {/* row */}
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a type="button">Chains</a>
                </li>
              </ol>
            </div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
              <div className="input-group search-area d-inline-flex me-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button type="button" className="input-group-text" onClick={getChains}>
                    <i className="flaticon-381-search-2" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Symbol</th>
                            <th>ExplorerUrl</th>
                            <th>RPCEncpoint</th>
                            <th>Coin Gecko Id</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {chains && chains?.length
                            ? chains?.map((item, index) => {
                                let ind = currentPage * pageSize - pageSize + index + 1;
                                return (
                                  <tr>
                                    <td className="patient-info ps-0">
                                      <span>{ind}</span>
                                    </td>
                                    <td className="patient-info ps-0">
                                      <span>
                                        <img
                                          src={item.logoUrl ? item.logoUrl : "/images/xeer-192.png"}
                                          alt=""
                                          style={{ width: "35px", height: "35px" }}
                                        />
                                      </span>
                                      <span className="text-nowrap ms-2">{item?.name ? item?.name : "-"}</span>
                                    </td>
                                    <td>{item.symbol ? item.symbol : "-"}</td>
                                    <td>{item.explorer ? item.explorer : "-"}</td>
                                    <td>{item.rpcEndpoint ? item.rpcEndpoint : "-"}</td>
                                    <td>{item.gecko_id ? item.gecko_id : "-"}</td>
                                    <td>
                                      <span className="me-3">
                                        <a type="button" className="edit-appointment" onClick={() => editChian(item)}>
                                          <i className="fa fa-pencil fs-18 " aria-hidden="true" />
                                        </a>
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !chains || !chains.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${editChainModal ? "modal fade show" : "modal fade"}`}
            id="exampleModal"
            style={editChainModal ? { display: "block" } : { display: "none" }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit Chain
                  </h5>
                  <button type="button" className="btn-close" onClick={closeEditChainModal}></button>
                </div>
                <div className="modal-body p-4">
                  <form>
                    <div className="row">
                      {error ? (
                        <div class="alert alert-danger p-2 px-3" role="alert">
                          {error}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-12">
                        <div className="form-group">
                          <label className="col-form-label">Logo Url :</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Logo Url"
                            value={logoUrl}
                            onChange={(e) => setLogoUrl(e.target.value)}
                          />
                          {logoUrlErr ? <small className="text-danger mx-1">{logoUrlErr}</small> : ""}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="col-form-label">Exlporer :</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name1"
                            placeholder="Exlporer"
                            value={explorer}
                            onChange={(e) => setExplorer(e.target.value)}
                          />
                          {explorerErr ? <small className="text-danger mx-1">{explorerErr}</small> : ""}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="col-form-label">Rpc Endpoint :</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name1"
                            placeholder="Rpc Endpoint"
                            value={rpcEndpoint}
                            onChange={(e) => setRpcEndpoint(e.target.value)}
                          />
                          {rpcEndpointErr ? <small className="text-danger mx-1">{rpcEndpointErr}</small> : ""}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="col-form-label">Gecko Id :</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name1"
                            placeholder="Gecko Id"
                            value={geckoId}
                            onChange={(e) => setGeckoId(e.target.value)}
                          />
                          {geckoIdErr ? <small className="text-danger mx-1">{geckoIdErr}</small> : ""}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-sm btn-danger" onClick={closeEditChainModal}>
                    Close
                  </button>
                  <button type="button" className="btn btn-sm btn-primary" onClick={updateChain}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
