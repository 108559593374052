import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Comman/Sidebar";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import axios from "../apis/api";
import Copy from "../Comman/Copy";
import { appContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import Pagination from "../Comman/Pagination";

export default function Index() {
  const navigate = useNavigate();
  const { logout, getDecToken, formatAddress, formatDate, toggleMenu } = useContext(appContext);
  const [dashboardData, setDashboardData] = useState("");
  const [actions, setActions] = useState(null);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getDashboardData = async () => {
    try {
      const token = getDecToken();
      await axios
        .get("getdashboarddata", {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            setDashboardData(res.data);
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const getActions = async () => {
    try {
      setLoading(true);
      const token = getDecToken();
      await axios
        .get(`todayActions?pageSize=${pageSize}&page=${currentPage}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            setLoading(false);
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
            setLoading(false);
          } else {
            setActions(res.data.items);
            setPages(res.data.pages ? res.data.pages : 1);
            setLoading(false);
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    setActions(null);
    getActions();
  }, [currentPage, pageSize]);

  const deleteAction = async (id) => {
    try {
      const token = getDecToken();
      await axios
        .post(
          `deleteaction`,
          { id: id },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error && !res.data.status && res.data?.isLogin === false) {
            return logout();
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            getActions();
          }
        });
    } catch (err) {
      console.log("error : ", err.message);
    }
  };

  return (
    <>
      <div id="main-wrapper" className={`show ${toggleMenu ? "menu-toggle" : ""}`}>
        <Header />
        <Sidebar />
        <div className="content-body">
          {/* row */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-danger">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Tokens</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.totaltokens ? dashboardData?.totaltokens : 0}
                          </h2>
                        </div>
                      </div>
                      <span className="border rounded-circle p-4">
                        <img src="images/svg/token.svg" height={34} width={34} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-success">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Tokeninfos</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.totaltokensinfos ? dashboardData?.totaltokensinfos : 0}
                          </h2>
                        </div>
                      </div>
                      <span className="border rounded-circle p-4">
                        <img src="images/svg/token.svg" height={34} width={34} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Chains</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.totalchains ? dashboardData?.totalchains : 0}
                          </h2>
                        </div>
                      </div>
                      <span className="border rounded-circle p-4">
                        <img src="images/chain.png" height={34} width={34} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-secondary">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Actions</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.totalactions ? dashboardData?.totalactions : 0}
                          </h2>
                        </div>
                      </div>
                      <span className="border rounded-circle p-4">
                        <img src="images/list.png" height={34} width={34} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-danger">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Today Tokens</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.totalatodaytokens ? dashboardData?.totalatodaytokens : 0}
                          </h2>
                        </div>
                      </div>
                      <span className="border rounded-circle p-4">
                        <img src="images/svg/token.svg" height={34} width={34} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-danger">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Today Tokens Info</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.totalatodaytokensinfo ? dashboardData?.totalatodaytokensinfo : 0}
                          </h2>
                        </div>
                      </div>
                      <span className="border rounded-circle p-4">
                        <img src="images/svg/token.svg" height={34} width={34} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-success">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Dead Tokens</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.totaldeadtokens ? dashboardData?.totaldeadtokens : 0}
                          </h2>
                        </div>
                      </div>
                      <span className="border rounded-circle p-4">
                        <img src="images/svg/token.svg" height={34} width={34} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">Today Actions</li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Address</th>
                            <th>Note</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {actions && actions?.length
                            ? actions?.map((item, index) => {
                                let ind = currentPage * pageSize - pageSize + index + 1;
                                return (
                                  <tr>
                                    <td className="patient-info ps-0">
                                      <span>{ind}</span>
                                    </td>
                                    <td>
                                      {item.address ? (
                                        <>
                                          {formatAddress(item.address)}&nbsp;
                                          <Copy data={item.address} />
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {item.note ? (
                                        <div
                                          dangerouslySetInnerHTML={{ __html: item.note }}
                                          style={{
                                            width: "200px",
                                            maxHeight: "70px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>{item.date ? formatDate(item.date) : "-"}</td>
                                    <td>
                                      <span className="me-3">
                                        <a
                                          type="button"
                                          className="edit-appointment mx-2"
                                          onClick={() => deleteAction(item._id)}
                                        >
                                          <i className="fa fa-trash fs-18 text-danger" aria-hidden="true" />
                                        </a>
                                        <button
                                          type="button"
                                          className="btn btn-sm  btn-primary px-3 py-2 mx-2"
                                          onClick={() => navigate(`/tokendetails`, { state: { id: item.address } })}
                                        >
                                          View {"->"}
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !actions || !actions.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
