import "./App.css";
import Index from "./Components/Home/Index";
import { Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import Token from "./Components/Tokan/Token";
import Chains from "./Components/Chais/Chains";
import "react-tooltip/dist/react-tooltip.css";
import TokenDetails from "./Components/TokenDetails";
import Actions from "./Components/Actions";
import FilteredTokens from "./Components/Tokan/FilteredTokens";
import ActionedTokens from "./Components/Tokan/ActionedTokens";
import DeadTokens from "./Components/Tokan/DeadTokens";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Index />} />
      <Route path="/tokens" element={<Token />} />
      <Route path="/chains" element={<Chains />} />
      <Route path="/tokendetails" element={<TokenDetails />} />
      <Route path="/actions" element={<Actions />} />
      <Route path="/filteredtokens" element={<FilteredTokens />} />
      <Route path="/actionedtokens" element={<ActionedTokens />} />
      <Route path="/deadtokens" element={<DeadTokens />} />
    </Routes>
  );
}

export default App;
