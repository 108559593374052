import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <>
      <div className="deznav">
        <div className="deznav-scroll">
          <ul className="metismenu" id="menu">
            <li className={`${pathname === "/dashboard" ? "mm-active active-no-child" : ""}`}>
              <Link className="ai-icon" to="/dashboard">
                <i className="flaticon-381-networking" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${pathname === "/tokens" ? "mm-active active-no-child" : ""}`}>
              <Link className="ai-icon" to="/tokens">
                <i className="flaticon-381-networking" />
                <span className="nav-text">Tokens</span>
              </Link>
            </li>
            <li className={`${pathname === "/filteredtokens" ? "mm-active active-no-child" : ""}`}>
              <Link className="ai-icon" to="/filteredtokens">
                <i className="flaticon-381-networking" />
                <span className="nav-text">Filtered Tokens</span>
              </Link>
            </li>
            <li className={`${pathname === "/actionedtokens" ? "mm-active active-no-child" : ""}`}>
              <Link className="ai-icon" to="/actionedtokens">
                <i className="flaticon-381-networking" />
                <span className="nav-text">Actioned Tokens</span>
              </Link>
            </li>
            <li className={`${pathname === "/deadtokens" ? "mm-active active-no-child" : ""}`}>
              <Link className="ai-icon" to="/deadtokens">
                <i className="flaticon-381-networking" />
                <span className="nav-text">Dead Tokens</span>
              </Link>
            </li>
            <li className={`${pathname === "/chains" ? "mm-active active-no-child" : ""}`}>
              <Link className="ai-icon" to="/chains">
                <i className="flaticon-381-networking" />
                <span className="nav-text">Chains</span>
              </Link>
            </li>
            <li className={`${pathname === "/actions" ? "mm-active active-no-child" : ""}`}>
              <Link className="ai-icon" to="/actions">
                <i className="flaticon-381-networking" />
                <span className="nav-text">Actions</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
